import React, { useRef, useContext, useEffect, useState } from "react"
import { isEqual } from "lodash"

import Dropzone from "./Dropzone"
import CartLayout from "../Layout/CartLayout"

import EpharmacyCart from "../Epharmacy/Cart"
// import ConfirmNavigationModal from "../Elements/ConfirmNavigationModal"
import { AppContext } from "../../context/AppContext"
import DocumentsCardRow from "../Cart/DocumentsCard/DocumentsCardRow"

import {
  zendeskUploadFiles,
  generateUploadFiles,
} from "services/zendeskService"

const MAX_FILE_SIZE_IN_BYTES = 4194304
const MAX_FILE_COUNT = 8

const Upload = ({ location, backRoute, nextRoute, flow }) => {
  const fileInputRef = useRef(null)
  const { dispatch, state } = useContext(AppContext)
  const { documents } = state

  const [filesUploaded, setFilesUploaded] = useState(state.documents)

  const hasRxRequiredMedicine = state?.epharmacy?.medicines?.some(
    (medicine) => !!medicine.rxRequired
  )

  const Cart = <EpharmacyCart open={{ documentsCard: true }} />

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  const handleDeleteDocument = async (index) => {
    const documentsList = [...documents]
    documentsList.splice(index, 1)
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        documents: [...documentsList],
      },
    })
  }

  useEffect(() => {
    let documentsWithNoToken =
      filesUploaded?.filter?.((document) => !document?.token)?.length || 0

    if (!state?.activeDocument && documentsWithNoToken > 0) {
      let currentDocumentWithNoToken = filesUploaded?.find?.(
        (document) => !document?.token
      )
      dispatch({
        type: "SAVE_ACTIVE_DOCUMENT",
        payload: currentDocumentWithNoToken,
      })
      uploadToZendesk({ currentDocument: currentDocumentWithNoToken })
    }
  }, [filesUploaded])

  const uploadToZendesk = async ({ currentDocument }) => {
    let documentsWithToken =
      filesUploaded?.filter?.((document) => !!document?.token)?.length + 1

    const UPLOADED_FILES = await zendeskUploadFiles([currentDocument])
    const GENERATED_FILE_TOKEN = await generateUploadFiles(UPLOADED_FILES)

    let newTempDocument = {
      ...currentDocument,
      token: GENERATED_FILE_TOKEN?.[0],
    }

    dispatch({
      type: "REMOVE_ACTIVE_DOCUMENT",
      payload: {
        ...newTempDocument,
      },
    })

    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: `Successfully uploaded ${documentsWithToken} file${
          documentsWithToken > 1 ? "s" : ""
        }`,
        color: "success",
      },
    })
  }

  return (
    <CartLayout
      isCentered
      desktop={10}
      fullhd={8}
      cartContainer={Cart}
      next={{
        label: location?.state?.previousRoute ? "Summary" : "Checkout",
        disabled:
          hasRxRequiredMedicine ||
          state?.epharmacy?.hasPartnerRequest ||
          state?.epharmacy?.isMedGrocerWellnessPatient
            ? state?.documents?.length > 0
              ? false
              : true
            : false,
        link: location?.state?.previousRoute || "/epharmacy/checkout",
        className: "is-hidden-desktop",
      }}
      back={{
        label: "Back",
        link: "/epharmacy/order",
        className: "is-hidden-desktop",
      }}
    >
      <Dropzone
        setFilesUploaded={setFilesUploaded}
        filesUploaded={filesUploaded}
        fileInputRef={fileInputRef}
        maxByteSize={MAX_FILE_SIZE_IN_BYTES}
        maxFileCount={MAX_FILE_COUNT}
        docType="RX"
      />
    </CartLayout>
  )
}

export default Upload
